<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <template v-for="({ icon, text, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
          class="white--text mt-n4"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div v-html="text" class="body-2"/>
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
            text: 'PT. ISTPRO INTI NUSA, Graha Atika Lt.5 Jl Warung Jati Barat No.7 RT.2/RW.5, Kalibata Pancoran, Kota Jakarta Selatan DKI Jakarta 12710',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: 'Telp: <a class="white--text" href="tel:02179187909">(021) 79-187-909</a>',
        },
        // {
        //   icon: 'mdi-email',
        //   title: 'Email',
        //   text: '<a class="white--text" href="mailto:cs@orionpenjaminan.co.id">cs<!-- >@. -->@<!-- >@. -->orionpenjaminan<!-- >@. -->.<!-- >@. -->co.id</a>',
        // },
      ],
    }),
  }
</script>
