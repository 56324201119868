import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import functions from './functions'
import './plugins'
import axios from 'axios'
import AOS from 'aos'
import VueSocialSharing from 'vue-social-sharing'
import 'aos/dist/aos.css'
// Format Money
import Money from 'v-money'
// Moment JS
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios
const plugins = {
  install () {
    Vue.functions = functions
    Vue.prototype.$functions = functions
  }
}
Vue.use(plugins)
Vue.use(VueSocialSharing)
// Format Money
Vue.use(Money)
Vue.config.productionTip = false
require('../src/styles/my.css')

new Vue({
  router,
  vuetify,
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
