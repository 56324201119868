// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: '',
          redirect: '/beranda',
          component: () => import('@/views/home/Index.vue'),
          meta: { menubar: true, tabs: 0 },
        },
        {
          path: 'beranda',
          name: 'beranda',
          component: () => import('@/views/home/Index.vue'),
          meta: { menubar: true, tabs: 0 },
        },
        {
          path: 'tentang-kami',
          name: 'tentang-kami',
          component: () => import('@/views/about/Index.vue'),
          meta: { menubar: true, src: require('@/assets/default-banner-home.jpg'), tabs: 2 },
        },
        {
          path: 'sejarah-istpro',
          name: 'sejarah-istpro',
          component: () => import('@/views/aboutusdetail/Index.vue'),
          meta: { menubar: true, src: require('@/assets/banner-history.jpg'), tabs: 2 },
        },
        {
          path: 'struktur-perusahaan',
          name: 'struktur-perusahaan',
          component: () => import('@/views/structure/Index.vue'),
          meta: { menubar: true, src: require('@/assets/banner-structure.jpg'), tabs: 2 },
        },
        {
          path: 'asuransi-rekanan',
          name: 'asuransi-rekanan',
          component: () => import('@/views/partner/Index.vue'),
          meta: { menubar: true, tabs: 3 },
        },
        // {
        //   path: 'penghargaan-perusahaan',
        //   name: 'penghargaan-perusahaan',
        //   component: () => import('@/views/awardsothers/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-awards.jpg') },
        // },
        // {
        //   path: 'penghargaan-detail/:url',
        //   name: 'penghargaan-detail',
        //   component: () => import('@/views/awardsdetail/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/contact.jpg') },
        // },
        // {
        //   path: 'laporan-keuangan',
        //   name: 'laporan-keuangan',
        //   component: () => import('@/views/financialreport/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-report.jpg') },
        // },
        {
          path: 'produk',
          name: 'produk',
          component: () => import('@/views/products/Index.vue'),
          meta: { menubar: true, src: require('@/assets/banner-product.jpg'), tabs: 2 },
        },
        {
          path: 'produk-detail/:url',
          name: 'produk-detail',
          component: () => import('@/views/productsdetail/Index.vue'),
          meta: { menubar: true, src: require('@/assets/pro.jpg'), tabs: 1 },
        },
        {
          path: 'produk-category/:url',
          name: 'produk-category',
          component: () => import('@/views/productscategory/Index.vue'),
          meta: { menubar: true, src: require('@/assets/pro.jpg'), tabs: 1 },
        },
        // {
        //   path: 'berita-lainnya',
        //   name: 'berita-lainnya',
        //   component: () => import('@/views/news/Index.vue'),
        //   meta: { menubar: true,src: require('@/assets/banner-news.jpg') },
        // },
        // {
        //   path: 'berita-detail/:url',
        //   name: 'berita-detail',
        //   component: () => import('@/views/newsdetail/Index.vue'),
        //   meta: { menubar: true },
        // },
        // {
        //   path: 'jaringan',
        //   name: 'jaringan',
        //   component: () => import('@/views/network/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-male.jpg'), tabs: 3 },
        // },
        // {
        //   path: 'pengaduan',
        //   name: 'pengaduan',
        //   component: () => import('@/views/complain/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-complain.jpg') },
        // },
        // {
        //   path: 'karir',
        //   name: 'karir',
        //   component: () => import('@/views/career/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        // {
        //   path: 'karir-detail/:url',
        //   name: 'karir-detail',
        //   component: () => import('@/views/careerdetail/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        // {
        //   path: 'karir-insan',
        //   name: 'karir-insan',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-career.jpg'), subtitle: 'Takaful Karyawan' },
        // },
        // {
        //   path: 'karir-agen',
        //   name: 'karir-agen',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-agen.jpg'), subtitle: 'Takaful Marketing Executive'  },
        // },
        // {
        //   path: 'karir-magang',
        //   name: 'karir-magang',
        //   component: () => import('@/views/careergroup/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-magang.jpg'), subtitle: 'Takaful Magang'  },
        // },
        // {
        //   path: 'polistakaful/:type/:id/:data',
        //   name: 'polistakaful',
        //   component: () => import('@/views/policy/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        // {
        //   path: 'klaim',
        //   name: 'klaim',
        //   component: () => import('@/views/claim/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-claim.jpg') },
        // },
        // {
        //   path: 'faq',
        //   name: 'faq',
        //   component: () => import('@/views/faq/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-faq.jpg') },
        // },
        // {
        //   path: 'perbedaan-takaful-dengan-konvensional',
        //   name: 'perbedaan-takaful-dengan-konvensional',
        //   component: () => import('@/views/different/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-differents.jpg') },
        // },
        // {
        //   path: 'akun',
        //   name: 'akun',
        //   component: () => import('@/views/account/Index.vue'),
        //   meta: { menubar: false },
        // },
        // {
        //   path: 'login',
        //   name: 'login',
        //   component: () => import('@/views/login/Index.vue'),
        //   meta: { menubar: false},
        // },
        // {
        //   path: 'vcard/:id',
        //   name: 'vcard',
        //   component: () => import('@/views/vcard/Index.vue'),
        //   meta: { menubar: false },
        // },
        // {
        //   path: 'wbs',
        //   name: 'wbs',
        //   component: () => import('@/views/wbs/Index.vue'),
        //   meta: { menubar: true, src: require('@/assets/banner-wbs.jpg') },
        // },
        // {
        //   path: 'kalkulator-kontribusi',
        //   name: 'kalkulator-kontribusi',
        //   component: () => import('@/views/calculator/Index.vue'),
        //   meta: { menubar: false, src: require('@/assets/pro.jpg') },
        // },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
          meta: { menubar: true },
        },
      ],
    },

  ],
})
export default router
